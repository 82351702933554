<template>
  <ClientOnly>
    <div class="container" style="height: 100%">
      <div class="header" :class="{ landing: isLandingPage, backgroundImage: !query }">
        <div class="container">
          <div class="breadcrumbs">
            <span>{{ $t('search.home') }}</span>
            <span><v-icon name="chevron-right-solid" class="breadcrumb-icon" /></span>
            <span
              ><b>{{ $t('search.search') }}</b></span
            >
          </div>
          <div class="container center search grow">
            <div class="search search-container">
              <div class="outer search-box-outer">
                <VIcon name="search-line" :size="-4" class="search-icon text-neutral-600" />
                <input
                  id="searchInput"
                  v-model="searchField"
                  class="input search-input-box"
                  :placeholder="$t('search.search')"
                  autocomplete="off"
                  @keydown.enter="onSubmit"
                />
                <v-button
                  v-show="searchField"
                  id="clearSearch"
                  style="width: 2.2rem"
                  icon="close-small-line"
                  dense=""
                  shape="Rounded"
                  @click="searchField = ''"
                ></v-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="query" class="search-results">
        <div class="fixed-width">
          <v-tabs class="tab-bar">
            <v-tab :label="$t('search.docs')" @click="documentType = DocumentType.Documentation" />
            <v-tab :label="$t('search.blog')" @click="documentType = DocumentType.Blogpost" />
            <v-tab :label="$t('search.knowledgebase')" @click="documentType = DocumentType.ZendeskArticle" />
            <v-tab-panel>
              <search-results-tab class="results-area" :query="query" :type="DocumentType.Documentation" />
            </v-tab-panel>
            <v-tab-panel>
              <search-results-tab class="results-area" :query="query" :type="DocumentType.Blogpost" />
            </v-tab-panel>
            <v-tab-panel>
              <search-results-tab class="results-area" :query="query" :type="DocumentType.ZendeskArticle" />
            </v-tab-panel>
          </v-tabs>
        </div>
      </div>
      <footer />
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { VButton, VIcon, VTab, VTabPanel, VTabs } from '@vonage/vivid-vue';
import SearchResultsTab from '@/components/search/SearchResultsTab.vue';
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DocumentType } from '@/types/search/search';

const query = ref('');
const searchField = ref('');
const documentType = ref(DocumentType.Documentation);

const isLandingPage = computed(() => query.value.length === 0);
const router = useRouter();
const route = useRoute();

const onSubmit = () => {
  if (searchField.value) {
    query.value = searchField.value;
    router.push({ query: { query: query.value } });
  }
};
onBeforeMount(() => {
  if ('query' in route.query && route.query.query !== '') {
    query.value = route.query.query as string;
    searchField.value = query.value;
  }
});
</script>

<style scoped lang="scss">
@import '../styles/media';

.header {
  width: 100%;
  min-height: 200px;
  background: var(--portal-search-box-background);
  display: flex;
  flex-direction: column;

  &.landing {
    height: 100%;
  }

  & > div {
    padding: 0 36px;
    height: 100%;
  }
}

.breadcrumbs {
  color: white;
  padding: 16px 0;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breadcrumb-icon {
  color: white;
  display: flex;
  align-items: center;
  font-size: 10px;
  padding: 10px;
}
.container {
  display: flex;
  flex-direction: column;

  &.grow {
    flex-grow: 1;
  }

  &.center {
    align-items: center;
  }

  &.search {
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
  }
}

.fixed-width {
  max-width: 1280px;
  padding: 0 24px;
  overflow-x: hidden;
  flex-grow: 1;
}

.search-field {
  max-width: 724px;
  flex-grow: 1;
}

.search-results {
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.results-area {
  padding: 24px 0;
}

@media screen and (max-width: $mobile-large) {
  .header {
    min-height: 88px;

    & > div {
      padding: 0 16px;
    }
  }

  .breadcrumbs {
    display: none;
  }

  .search-results {
    padding-top: 0;
  }

  .results-area {
    padding: 24px 24px;
  }

  .fixed-width {
    padding: 0;
  }
}

.tab-bar {
  padding: 0 1.5rem;
}

.backgroundImage {
  background-image: url('/assets/desktop-search-background.webp');
  background-size: cover;
}

@media screen and (max-width: $tablet-small) {
  .backgroundImage {
    background-image: url('/assets/mobile-search-background.png');
  }
}

.search-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 3rem;
}
.search-box-outer {
  display: flex;
  flex-direction: row;
  width: 58.333333%;
  outline: #929292 solid 0.0625rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.25rem;
  align-items: center;
  height: 2.5rem;
}
.search-icon {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
  padding: 0.0625rem;
  justify-content: center;
  width: 3.3rem;
  display: flex;
}
.search-input-box {
  font: var(--dev-docs-search-input-box-font);
  outline: 0.125rem solid transparent !important;
  outline-offset: 0.125rem;
  flex-grow: 1;
  width: 80%;
  height: 2rem;
  border-style: solid;
  border-width: 0;
}
</style>
